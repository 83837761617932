import { ApolloClient, ApolloClientOptions, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";
import buildHasuraProvider from 'ra-data-hasura'
import { call, put, select } from "redux-saga/effects";
import { setApolloClient, setScaleDataProvider } from "./ProviderReducer";
import { customBuildFields } from "./scaleQueryExtensions";
import { buildScaleExtensions, ScaleDataProvider } from "./ScaleDataProvider";
import config from "../../configuration/authConfig";
import { ScaleState } from "../Store";
import { isTokenExpired } from "../../MainWithAuth";

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    console.log("QLerror", graphQLErrors)
    for (let err of graphQLErrors) {
      // Check if the error is related to an expired token
      if (err.extensions?.code === "invalid-jwt") {
        console.log("JWT token expiry!!!");
        // Handle token expiry (e.g., refresh token or redirect to login)
      }
    }
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
    // Handle network errors as needed
  }
});

const loginOrg = (state: ScaleState): string | undefined => state.login.organization

function* initApolloClient() {
  const organization: string | undefined = (yield select(loginOrg))
  const httpLink = createHttpLink({
    uri: `${config.hasuraUrl}v1/graphql`,
  })

  const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('scale_token');
    if (token && isTokenExpired(token)) {
      console.log("I have an invalid JWT token, but I am trying to query.", token)
    }

    return {
      headers: {  
        ...headers,
        ...(organization !== undefined) && { 'x-hasura-organizationx-id': organization },
        'content-type': 'application/json',
        authorization: token ? `Bearer ${token}` : "",
      }
    }
  })

  console.log('initApolloQuery');
  const cache = new InMemoryCache()

  const apolloOptions: ApolloClientOptions<InMemoryCache> = {
    link: errorLink.concat(authLink).concat(httpLink),
    // @ts-ignore
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      }
    }
  }
  console.log(`ProviderSagas: initializing apollo for '${organization}'`, apolloOptions);
  return new ApolloClient(apolloOptions);
}

type HasuraClientOpts = Parameters<typeof buildHasuraProvider>[0]

const hasuraProvider = (client: ApolloClient<unknown>) => {
  const buildOpts = { buildFields: customBuildFields }
  // @ts-ignore
  const clientOpts: HasuraClientOpts = { client }
  return buildHasuraProvider(clientOpts, buildOpts)
}

function* initScaleDataProvider(client: ApolloClient<any>) {
  const scaleDataProvider: ScaleDataProvider = {
    ...hasuraProvider(client),
    ...buildScaleExtensions(client),
  }

  yield put(setScaleDataProvider(scaleDataProvider))
}

export function* providerSagas() {
  const client: ApolloClient<InMemoryCache> = (yield call(initApolloClient))
  yield put(setApolloClient(client))
  yield call(initScaleDataProvider, client)
}
