import {
  Card,
  CardContent,
  CardHeader,
} from "@mui/material"
import { Button } from "react-admin"
import ProjectFileList from "./ProjectFileList"
import { ScalePermissions } from "../../Main"
import * as featureFlags from "../../configuration/featureFlags"

const AddNewFileButton = () => (
  <Button
    label='Add File'
    variant='contained'
    style={{ marginBottom: '8px' }}
    onClick={() => console.log('TODO implement new file upload')}
  />
)

type FilesCardProps = {
  projectId: string,
  permissions: ScalePermissions
}

const ProjectFilesCard = (props: FilesCardProps) => {
  if (featureFlags.isStorageProxyEnabled(props.permissions)) {
    return (
      <Card>
        <CardHeader title='Project Attachments' />
        <CardContent>
          {/* <AddNewFileButton /> */}
          <ProjectFileList {...props} />
        </CardContent>
      </Card>
    )
  }
  return null
}

export default ProjectFilesCard
