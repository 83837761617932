import { ScalePermissions } from "../Main"

const isTrue = (envVar: string): boolean => {
  return 'true' === (process.env[envVar] ?? 'false').toLowerCase().trim()
}

const storageProxyEnabled = isTrue('REACT_APP_SCALE_STORAGE_PROXY_ENABLED')
const storageProxyAdminAccessEnabled = isTrue('REACT_APP_SCALE_STORAGE_PROXY_ADMIN_ACCESS_ENABLED')

export const isStorageProxyEnabled = (userPermissions: ScalePermissions) =>
  storageProxyEnabled || (userPermissions.isAdmin && storageProxyAdminAccessEnabled)
