import { ApolloClient, DocumentNode } from '@apollo/client'
import gql from 'graphql-tag'
import { DataProvider } from 'react-admin'
import { useSelector } from 'react-redux'
import { ScaleState, store } from '../Store'
import { FileUpload, FileUploadUrl, ProjectFile } from '../../model/ScaleTypes'
import { isNotNil } from '../../util/ScaleUtils'

const LIST_PROJECT_FILES = gql(`
  query ListBusinessFiles($businessId: uuid!) {
    api_business(where: {id: {_eq: $businessId}}) {
      id
      files {
        name
        url
      }
    }
  }
`)

const GET_PROJECT_FILE_UPLOAD_URLS = gql(`
  query ListBusinessFiles($businessId: uuid!) {
    api_business(where: {id: {_eq: $businessId}}) {
      id
      fileUploadUrls {
        contentMd5
        contentType
        name
        url
      }
    }
  }
`)

const GET_PROJECT_REPORT_TEMPLATE = gql(`
  query GetProjectReportTemplate($projectId: uuid!) {
    api_business(where: {id: {_eq: $projectId}}) {
      reportTemplate {
        name
        url
      }
    }
  }
`)

interface ScaleHasuraExtensions {
  listBusinessFiles: (params: {businessId: string, forceReload?: boolean}) => Promise<ProjectFile[]>
  getFileUploadUrls: (params: {businessId: string, files: FileUpload[]}) => Promise<FileUploadUrl[]>
  getReportTemplate: (params: {projectId: string}) => Promise<ProjectFile | null>
}

type StrictDataProvider = Pick<DataProvider,
  'getList' | 'getOne' | 'getMany' | 'getManyReference' |
  'update' | 'updateMany' |
  'create' |
  'delete' | 'deleteMany'>

export interface ScaleDataProvider extends StrictDataProvider, ScaleHasuraExtensions {}

export const buildScaleExtensions = (client: ApolloClient<unknown>): ScaleHasuraExtensions => {
  const executeQuery = (query: DocumentNode, variables: any) => client.query({
    query,
    variables,
    fetchPolicy: 'network-only',
    pollInterval: 0,
  })

  return {
    listBusinessFiles: async ({ businessId }): Promise<ProjectFile[]> => {
      const { data } = await executeQuery(LIST_PROJECT_FILES, { businessId })
      if (isNotNil(data)) {
        return data.api_business[0]?.files ?? [] as ProjectFile[]
      } else {
        throw new Error('Loading list of business files failed')
      }
    },

    getFileUploadUrls: async ({ businessId, files }): Promise<FileUploadUrl[]> => {
      const { data } = await executeQuery(GET_PROJECT_FILE_UPLOAD_URLS, { businessId, files })
      if (isNotNil(data)) {
        return data.api_business[0]?.fileUploadUrls ?? [] as FileUploadUrl[]
      } else {
        throw new Error('Getting file upload urls failed')
      }
    },

    getReportTemplate: async ({ projectId }): Promise<ProjectFile | null> => {
      const { data } = await executeQuery(GET_PROJECT_REPORT_TEMPLATE, { projectId })
      if (isNotNil(data)) {
        return data.api_business[0]?.reportTemplate as ProjectFile ?? null
      } else {
        throw new Error('Getting project report template failed')
      }
    },
  }
}

const dataProviderSelector = (state: ScaleState) => state.provider.dataProvider

export const useScaleDataProvider = (): ScaleDataProvider => {
  return useSelector(dataProviderSelector)
}

export const getScaleDataProvider = (): ScaleDataProvider | undefined => {
  return store.getState()?.provider?.dataProvider
}
