import { User, useAuth0 } from '@auth0/auth0-react';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import {
  Admin,
  AuthProvider,
  CustomRoutes,
  EditGuesser,
  ListGuesser,
  Resource,
  localStorageStore
} from 'react-admin';
import englishMessages from './configuration/en';
import BusinessEdit from './resources/scale_business/BusinessEdit';
import { BusinessWithInfoShow } from './resources/api_business/BusinessWithInfoShow';
import { OrganizationCreate, OrganizationEdit, OrganizationList } from './resources/scale_organization/OrganizationAdmin';
import { OrganizationUnitCreate, OrganizationUnitEdit, OrganizationUnitList } from './resources/scale_organizationUnit/OrganizationUnitAdmin';
import Dashboard from './pages/Dashboard';
import { MaturityDescriptionEdit } from './resources/scale_maturityDescription/MaturityDescriptionEdit';
import { MaturityDescriptionList } from './resources/scale_maturityDescription/MaturityDescriptionList';
import { theme } from './layout/theme';
import { LoadingAnimation } from './components/LoadingAnimation';
import { QuickCreate } from './resources/scale_business/QuickCreate';
import { Navigate, Route } from 'react-router-dom';
import TermsView from './pages/TermsView';
import EarlyInsightsView from './pages/EarlyInsightsView';
import BenchmarkingView from './pages/BenchmarkingView';
import ModifySubscriptionView from './pages/ModifySubscriptionView';
import TrelloCallback from './integrations/trello/TrelloCallback';
import PortfolioHistoryView from './pages/PortfolioHistoryView';
import TrelloConfigurationScreen from './integrations/trello/TrelloConfigScreen';
import MaturityView from './pages/MaturityView';
import SteeringReportView from './pages/SteeringReportView';
import BusinessListView from './pages/BusinessListView';
import StatusScreen from './pages/StatusScreen';
import { useContext, useEffect, useMemo, useState } from 'react';
import { SettingsContext } from './state/SettingsContext';
import ScaleLayout from './layout/ScaleLayout';
import AdminSettings from './pages/AdminSettings';
import PlanEdit from './resources/scale_plan/PlanEdit';
import PlanCreate from './resources/scale_plan/PlanCreate';
import ImageUploader from './pages/ImageUploader';
import InviteColleagueView from './pages/InviteColleagueView';
import CustomReportPage from './pages/CustomReportPage';
import { ScaleDataProvider, useScaleDataProvider } from './state/provider/ScaleDataProvider';


const i18nProvider = polyglotI18nProvider(locale => {
  /*  if (locale === 'fr') {
        return import('./i18n/fr').then(messages => messages.default);
    }*/

  // Always fallback on english
  return englishMessages;
}, 'en');

const decorateWithDemoFilter = (filter: string, dataProvider: ScaleDataProvider): ScaleDataProvider => {
  return {
    ...dataProvider,
    getList: (resource, params) => {
      const extendedParams = {
        ...params,
        filter: {
          ...params.filter
        }
      };
      if (resource === "api_business" || resource === "api_businessHistoryWithScore" || resource === "api_history_business") {
        extendedParams.filter[`organizationUnit#organization#friendlyId@${filter}`] = 'demo-org'
      } if (resource === "api_averageScoreByDay") {
        extendedParams.filter[`business#organizationUnit#organization#friendlyId@${filter}`] = 'demo-org'
      } else if (resource === "scale_organizationUnit") {
        extendedParams.filter[`organization#friendlyId@${filter}`] = 'demo-org'
      }
      return dataProvider.getList(resource, extendedParams);
    },
    update: (resource: string, params: any) => {
      //console.log(`Updating resource: ${resource}`, params);
      const result = dataProvider.update(resource, params);
      return result;
    },
    delete: (resource: string, params: any) => {
      //console.log(`Deleting resource: ${resource}`, params);
      const result = dataProvider.delete(resource, params);
      return result;
    }
  };
}

/**
 * Permissions related to Scale User
 */
export interface ScalePermissions {
  user: User | undefined;
  roles: string[];
  // organisation friendly name
  organization: string;
  isUnverified: boolean;
  isAdmin: boolean;
  isFreemium: boolean;
  hasUnknownOrganization: boolean;
  // indicates if the user's access is limited to a given set of businesses
  isLimitedAccess: boolean;
  // list of id's that are available for the user, used to filter queries
  allowedBusinessIds: string[];
}

export function Main() {
  const [usedDataProvider, setUsedDataProvider] = useState<ScaleDataProvider | null>(null);
  const scaleDataProvider = useScaleDataProvider()
  const settings = useContext(SettingsContext);

  const dataProviderWithDemoMode = useMemo(() => {
    if (!scaleDataProvider) return null;
    return decorateWithDemoFilter("_eq", scaleDataProvider);
  }, [scaleDataProvider]);

  const dataProviderWithoutDemoMode = useMemo(() => {
    if (!scaleDataProvider) return null;
    return decorateWithDemoFilter("_neq", scaleDataProvider);
  }, [scaleDataProvider]);

  const store = localStorageStore();
  store.setItem('sidebar.open', false);

  // @TODO Clean up to a separate file?
  const { user, loginWithRedirect, isAuthenticated, logout } = useAuth0();
  const authProvider = {
    // called when the user attempts to log in
    login: (url: string) => {
      return loginWithRedirect({ redirectUri: url })
    },
    // called when the user clicks on the logout button
    logout: async () => {
      console.log('logout')
      localStorage.removeItem('scale_token')
      logout()
    },
    // called when the API returns an error
    checkError: ({ status }: { status: number }) => {
      if (status === 401 || status === 403) {
        return Promise.reject();
      }
      return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
      return isAuthenticated ? Promise.resolve() : Promise.reject()
    },
    getIdentity: async () => {
      return {
        id: user?.email,
        fullName: user?.name ?? 'User',
        avatar: user?.picture,
        ...user
      }
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: async (): Promise<ScalePermissions> => {
      const hasuraClaims = user ? user['https://hasura.io/jwt/claims'] || {} : {};

      return Promise.resolve({
        user,
        roles: hasuraClaims['x-hasura-allowed-roles'],
        organization: hasuraClaims['x-hasura-organization-id'],
        hasUnknownOrganization: hasuraClaims['x-hasura-organization-id'] === 'unknown' || hasuraClaims['x-hasura-organization-id'] === undefined || hasuraClaims['x-hasura-organization-id'] === null,
        isUnverified: user?.email_verified !== true,
        isAdmin: hasuraClaims['x-hasura-allowed-roles']?.indexOf("scale-admin") !== -1,
        isFreemium: hasuraClaims['x-hasura-allowed-roles']?.indexOf("freemium") !== -1,
        allowedBusinessIds: hasuraClaims['allowed-business-ids'] || [],
        isLimitedAccess: (!!hasuraClaims['allowed-business-ids'])
      });

    }
  } as AuthProvider

  useEffect(() => {
    setUsedDataProvider(settings.interfaceSettings.demoMode ? dataProviderWithDemoMode : dataProviderWithoutDemoMode);
    settings.setDataProviderUpdated(Date.now());
  }, [dataProviderWithDemoMode, dataProviderWithoutDemoMode, settings])

  if (!scaleDataProvider || !dataProviderWithDemoMode || !dataProviderWithoutDemoMode || !usedDataProvider) return <LoadingAnimation loadingText='Loading DataProvider' />

  return (
    <Admin
      key={settings.dataProviderUpdated}
      authProvider={authProvider}
      dashboard={Dashboard}
      layout={ScaleLayout}
      theme={theme as any}
      title="Scale App"
      dataProvider={usedDataProvider}
      i18nProvider={i18nProvider}>

      <Resource name="scale_business" create={QuickCreate} edit={BusinessEdit} />

      <Resource name="api_business" options={{ label: "Business Info" }} list={BusinessListView} show={BusinessWithInfoShow} />
      <Resource name="api_businessHistoryWithScore" />
      <Resource name="api_averageScoreByDay" />
      <Resource name="api_priorityList" />

      <Resource name="api_maturityModelByOrganization" />
      <Resource name="api_maturityCategoryByOrganization" />
      <Resource name="api_maturityLevelByOrganization" />

      <Resource name="api_scoreByDayAndCategory" />

      <Resource name="api_maturityLevelByOrganization" />

      <Resource name="scale_maturityScores" />
      <Resource name="scale_insights" />
      <Resource name="scale_maturityModel" list={ListGuesser} edit={EditGuesser} />
      <Resource name="scale_maturityModelContext" list={ListGuesser} edit={EditGuesser} />

      <Resource name="scale_maturityModelCategory" />
      <Resource name="api_tagList" />

      <Resource name="scale_organizationUnit" options={{ label: "Units" }} list={OrganizationUnitList} create={OrganizationUnitCreate} edit={OrganizationUnitEdit} />
      <Resource name="scale_organization" options={{ label: "Organizations" }} list={OrganizationList} create={OrganizationCreate} edit={OrganizationEdit} />
      <Resource name="scale_plan" create={PlanCreate} edit={PlanEdit} />


      {/* Basic Constants */}
      <Resource name="scale_status" />

      <Resource name="scale_maturityMilestone" />

      {/* Resources for Maturity Model */}
      <Resource name="scale_maturityCategory" options={{ label: "Maturity Category" }} list={ListGuesser} edit={EditGuesser} />
      <Resource name="scale_maturityLevel" options={{ label: "Maturity Level" }} list={ListGuesser} edit={EditGuesser} />
      <Resource name="scale_maturityDescription" options={{ label: "Maturity Description" }} list={MaturityDescriptionList} edit={MaturityDescriptionEdit} />

      <CustomRoutes>
        <Route path="/benchmarking" element={<BenchmarkingView />} />
        <Route path="/businessList" element={<BusinessListView />} />
        <Route path="/earlyInsights" element={<EarlyInsightsView />} />
        <Route path="/invitecolleague" element={<InviteColleagueView />} />
        <Route path="/managesubscription" element={<ModifySubscriptionView />} />
        <Route path="/trello-callback" element={<TrelloCallback />} />
        <Route path="/history" element={<PortfolioHistoryView />} />
        <Route path="/integrations" element={<TrelloConfigurationScreen />} />
        <Route path="/businessMaturity/:businessId" element={<MaturityView />} />
        <Route path="/steeringReport" element={<SteeringReportView />} />
        <Route path="/termsandconditions" element={<TermsView />} />
        <Route path="/adminSettings" element={<AdminSettings />} />
        <Route path="/test" element={<ImageUploader />} />
        <Route path="/businessReport/:businessId" element={<CustomReportPage />} />

        <Route path="/api_latestBusinessInfoFull/:businessId/:command" element={<Navigate replace to={"/api_business/:businessId/:command"} />} />
      </CustomRoutes>
      <CustomRoutes noLayout>
        <Route path="/statusScreen" element={<StatusScreen />} />,
      </CustomRoutes>

    </Admin>
  )
}
