import {
  Alert,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { ScalePermissions } from '../../Main'
import * as featureFlags from '../../configuration/featureFlags'
import { ProjectFile } from '../../model/ScaleTypes'
import { useScaleDataProvider } from '../../state/provider/ScaleDataProvider'
import { LoadingIndicator } from '../../components/LoadingIndicator'

type FileListProps = {
  projectId: string
  permissions: ScalePermissions,
}

const useProjectFiles = ({ projectId, permissions }: FileListProps): [ProjectFile[]?, Error?] => {
  const dataProvider = useScaleDataProvider()
  const [files, setFiles] = useState<ProjectFile[] | undefined>(undefined)
  const [error, setError] = useState<Error | undefined>(undefined)

  useEffect(() => {
    if (dataProvider && featureFlags.isStorageProxyEnabled(permissions)) {
      dataProvider.listBusinessFiles({ businessId: projectId })
                  .then(setFiles)
                  .then(() => setError(undefined))
                  .catch(setError)
    }
  }, [dataProvider, permissions, projectId])

  return [files, error]
}

const fileDetails = (file: ProjectFile) => (
  <ImageListItem key={file.url}>
    <img
      src={file.url}
      alt={file.name} />
    <ImageListItemBar
      title={file.name}
      position='top' />
  </ImageListItem>
)

const Loader = () => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <LoadingIndicator loadingText='Loading files' />
  </div>
)

const ProjectFileList = (props: FileListProps) => {
  const [files, error] = useProjectFiles(props)

  if (files === undefined) return <Loader />
  if (files.length === 0) return <Alert severity='info'>No files have been uploaded.</Alert>
  if (error) return <Alert severity='warning'>File listing failed, please reload to try again.</Alert>
  return (
    <ImageList
      cols={Math.min(files.length, 5)}
      gap={8}
      variant='quilted'>
        { files.map(fileDetails) }
    </ImageList>
  )
}

export default ProjectFileList
