import React, { Fragment } from 'react';
import { HasScore, MaturityModel } from '../model/ScaleTypes';
import { useTranslate } from 'react-admin';
import { Alert, Card, CardContent, CardHeader, Typography } from '@mui/material';
import InfoIconTooltip from './InfoIconTooltip';
import { ChangeIndicator } from './common/ChangeIndicator';

export interface MaturityScoreCardProps {
  data: HasScore[];
  maturityModel?: MaturityModel;
}

export const MaturityScoreCard: React.FC<MaturityScoreCardProps> = ({ data, maturityModel }) => {
  function getLevelName(maturityScore: number): string {
    // TODO: Fix null pointer bug that occurs if current user doesn't have access to particular business case. How to reproduce: open project, change organisation from Auth0, reload page. Level is undefined and throws error.
    return maturityModel?.levels ? maturityModel?.levels.filter((level) => { return level.level.level === Math.floor(Math.min(maturityModel?.maxLevel - 1, maturityScore)) + 1 })[0].level.name : "";
  };
  const translate = useTranslate();
  if (!maturityModel) return <Alert severity='error'>{translate('errors.maturity_model_missing')}</Alert>


  const MaturityScoreCardContent: React.FC = () => {
    if (data.length === 0) return <Alert severity='warning'>{translate('business.assessment_missing')}</Alert>

    const levelName = getLevelName(data[data.length - 1].score);
    const score = Math.round((data[data.length - 1].score) * 10) / 10;
    const change = data.length >= 2 ? data[data.length - 1].score - data[data.length - 2].score : 0;


    return <Fragment><Typography variant="h2">{score}</Typography>
      {data.length > 1 && <ChangeIndicator change={change} changeMode='absolute' />}
      <Typography variant="h5">{levelName}</Typography>
    </Fragment>
  }

  return (
    <Card>
      <CardHeader
        title={translate('pos.businessShow.maturityScore')}
        action={<InfoIconTooltip title={translate('pos.definitions.maturityScore', { levels: maturityModel?.maxLevel })} />}
      />
      <CardContent style={{ textAlign: 'center', alignItems: 'center' }}>
        <MaturityScoreCardContent />
      </CardContent>
    </Card>
  );
};
