import { CardActions, CardHeader, List, ListItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { Business, MaturityDescriptionExtended, MaturityModel, MaturityScore, MaturityScoreExtended } from "../model/ScaleTypes";
import useMaturityModel from "../state/useMaturityModel";
import { Button } from "react-admin";
import { ThreeDots } from "react-loader-spinner";
import { groupByReduceFunction } from "../util/ScaleUtils";
import dayjs from '../configuration/configuredDayjs';

const OpenAI = require("openai");

const API_KEY = "sk-proj-B7DNRAxCDDlfLy5ZXKh9T3BlbkFJ6335W3FBYHH0puWJldEc"

const loadingTexts: string[] = [
  "Brewing fresh ideas...☕️",
  "Unleashing creativity dragons...🐉",
  "Sprinkling some innovation dust...✨",
  "Warming up the brainwaves...🌊",
  "Channeling inner Einstein...🧠",
  "Syncing with the innovation muse...🎨",
  "Brainstorming with bots...🤖",
  "Unlocking hidden potential...🔐",
  "Tuning the creative engines...🚀",
  "Merging with brilliance...💡",
  "Preparing some eureka moments...💥",
  "Loading awesomeness...100%...🚀",
  "Generating brilliant insights...⚡"
];

// Function to extract JSON strings from the provided text
function extractJSONStrings(text: string): string[] {
  const jsonRegex = /```json\s*([\s\S]*?)\s*```/g;
  let match;
  const jsonStrings: string[] = [];

  while ((match = jsonRegex.exec(text)) !== null) {
    jsonStrings.push(match[1]);
  }

  return jsonStrings;
}

function getRandomLoadingText(): string {
  const randomIndex = Math.floor(Math.random() * loadingTexts.length);
  return loadingTexts[randomIndex];
}

type Message = {
  role: string,
  content: string,
}

const ROLE_PROMPT = { role: "system", content: `You are an AI startup coach specializing in helping teams develop new business ventures within large enterprises.` };
const PROJECT_PROMPT = {
  role: "system", content:
    `You will be provided with the following information about a project:
* PROJECT NAME
* PROJECT DESCRIPTION
* CURRENT PERSPECTIVE (The area of the project that the team is currently focused on)
* CURRENT OBJECTIVE (The team's desired outcome, please read this carefully, and almost literally)
* DESCRIPTION (Details about the objective)
* EXAMPLES (Actions/evidence suggesting progress towards the objective)
* EARLIER NOTES (History of the team's work)`};

function noteToPrompt(score: MaturityScoreExtended, maturityModel: MaturityModel) {
  return `
NOTE ${score.id}
* NOTE DATE: ${score.createdAt}
* OBJECTIVE CATEGORY NAME: ${score.category?.name}
* TEAM'S NOTES: ${score.notes} 
* TEAM'S ASSESSMENT OF OBJECTIVE COMPLETION: ${score.score === score.notesScore}
  `;
}

type Summary = {
  summary: string,
  highlights: string[],
  actionItems: string[],
  openQuestions: string[],
}


// Define the type for props
interface SummaryCoachProps {
  business: Business;
  scoresWithNotes: MaturityScoreExtended[];
}
export const SummaryCoach: React.FC<SummaryCoachProps> = ({ business, scoresWithNotes }) => {
  const { maturityModel, loading: mmLoading } = useMaturityModel();
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState<Summary | undefined>();


  if (mmLoading) return <p>Loading</p>
  if (!maturityModel) return <p>Error</p>

  //      * OBJECTIVE TITLE: (short title of the desired objective towards which the note and reflection is)
  //* OBJECTIVE DESCRIPTION: (longer details what the objective is about)


  const notesByDate = groupByReduceFunction(scoresWithNotes, note => dayjs(note.createdAt).format("YYYY-MM-DD"));
  console.log(notesByDate);

  const dates = Object.keys(notesByDate).sort().reverse();


  const notesToSummarize = notesByDate[dates[0]];


  const prompt = [
    ROLE_PROMPT,
    PROJECT_PROMPT,
    {
      role: "system", content: `In addition you will be provided a list of notes from a review and planning session of the project team.
      The Information will be provided for each objective reviewed and considered during the planning session.The format for each objective will be:
      * NOTE DATE: (Time and date of the note in question)
      * OBJECTIVE CATEGORY NAME: (type of objective)
      * TEAM'S NOTES: (team's written notes on their discussion and reflection on the objective and what they have done and think they need still to do) 
      * TEAM'S ASSESSMENT OF OBJECTIVE COMPLETION: (either true or false) 
      `},
    { role: "system", content: `Your task is to create a helpful succint summary of the reflections of the team on the different objectives.Please pay special focus on summarizing the next steps and ACTION ITEMS that team has decided and also please highlight if you think some decisions still need to be made by the team.` },
    {
      role: "system", content: `Provide your summary in the following format:
\`\`\`json  
{
      "summary": "<short summary, maximum of 2 - 3 sentences>",
      "highlights": [ "<array of key highlights that you think succintly capture the essence of the session>" ],
      "actionItems": [ "<array of decided next steps and actions items from the team, as bullets>" ],
      "openQuestions": [ "< array of open topics or questions to explore>" ]
}
\`\`\`    
      `},
    {
      role: "system", content: `
**Additional Guidance:**
* Write succintly, keeping sentences short.
* Focus on plans, open actions and questions.
* Identify topics and shifts in ownership, responsbility, agreement and alignment.
* Use similar language as the user and when it makes sense, refer to the different stakeholders with the names and acronyms used by the user.
* For the OPEN QUESTIONS AND TOPICS, it is good to write concrete questions that were left open
`},
    {
      role: "user", content: `

        PROJECT NAME: ${business.name}

        PROJECT DESCRIPTION: ${business.description}

        NOTES ON OBJECTIVES: 

        =====
        ${notesToSummarize.map(note => noteToPrompt(note, maturityModel)).join("\n=====\n")}
      `},
    { role: "user", content: `Provide me a succint and helpful summary of the notes from this day.` }
  ]

  console.log("prompt", prompt);

  async function handleSubmit(): Promise<void> {
    const openai = new OpenAI({
      apiKey: API_KEY,
      dangerouslyAllowBrowser: true
    });
    setLoading(true);
    try {
      const result = await openai.chat.completions.create({
        messages: prompt,
        model: "gpt-4o",
        temperature: 0.5,
        max_tokens: 2048,
      });
      const resultText = result.choices[0].message.content;
      console.log(resultText);
      const summary = JSON.parse(extractJSONStrings(resultText)[0]);
      console.log(summary);
      setSummary(summary);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }

  return <Card>
    <CardHeader title={
      <Typography variant="button" style={{ fontSize: '0.9em', marginBottom: '10px' }}>🤖 Scale Coach (beta)</Typography>
    } />
    <CardContent>
      {summary && <>
        <Typography>{summary.summary}</Typography>
        <List sx={{ listStyleType: 'disc' }}>
          {summary.actionItems.map(item => <ListItem sx={{ display: 'list-item' }}>{item}</ListItem>)}

        </List>
      </>

      }
    </CardContent>
    <CardActions>
      <Button label="Summarize" type="submit" color="primary" variant='outlined' disabled={loading} onClick={handleSubmit} />
    </CardActions>
  </Card>
}


// Define the type for props
interface HypothesisCoachProps {
  business: Business;
  scoresWithNotes: MaturityScoreExtended[];
  maturityDescription: MaturityDescriptionExtended;
}
export const HypothesisCoach: React.FC<HypothesisCoachProps> = ({ business, scoresWithNotes, maturityDescription }) => {
  const [prompt, setPrompt] = useState<Message[]>([]);
  const [loading, setLoading] = useState(false);

  const [examples, setExamples] = useState<Example[]>([]);

  if (prompt.length === 0) {
    setPrompt([
      ROLE_PROMPT,
      PROJECT_PROMPT,
      { role: "system", content: `Your task is to generate a list of testable hypotheses and concrete tests related to the project's objective. These hypotheses should propose a causal relationship between the team's actions and measurable outcomes that indicate progress towards achieving the objective.` },
      {
        role: "system", content: `As an output please provide a list of hypotheses and corresponding tests in the following format:
        
      ### Example[Number]:
\`\`\`json  
      {
         "hypothesis": "If [team action], then [measurable outcome] will happen because [reasoning]",
         "test": "[Clear plan to implement the action, collect data, and analyze results]",
         "successCriteria": "[Pre-defined thresholds or benchmarks for the outcome]"
         "rationale": "[Your rationale about why you suggest this hypothesis for for this team. Base your rationale to a specific facts or notes from projects situation that is the basis for your suggestions.']"
      }
\`\`\`         
`},
      {
        role: "system", content: `
**Additional Guidance:**
* Specifically aim to solve the CURRENT OBJECTIVE, and not the entire project. Focus on what literally the objective says.
* Consider the CURRENT PERSPECTIVE, while other validations might be important, your guidance should be focused on the CURRENT OBJECTIVE.
* Also consider CURRENT LEVEL. It also gives you guidance on what CURRENT OBJECTIVE is related to. For example "Problem Validation" means that the CURRENT OBJECTIVE is related to validating that the problem exists.
* Prioritize hypotheses based on potential impact and feasibility.
* When providing suggestions, where reasonable, use the language and terms that refer the content from the EARLIER NOTES.
* Encourage continuous refinement of hypotheses and tests based on learnings.
* Be aware that some objectives might have qualitative components, not just quantitative ones. 
`},
      {
        role: "user", content: `

        PROJECT NAME: ${business.name}

        PROJECT DESCRIPTION: ${business.description}

        CURRENT PERSPECTIVE: ${maturityDescription.category.name}

        CURRENT OBJECTIVE: ${maturityDescription.name}

        CURRENT LEVEL: ${maturityDescription.level.name}

        DESCRIPTION: ${maturityDescription.description}

        EXAMPLES: ${maturityDescription.examples}

        EARLIER NOTES:
        
        ${scoresWithNotes.map((note, index) => `NOTE FOR LEVEL ${note.level}: 
          ${note.notes}`)}
        
      `},
      { role: "user", content: "Provide me a list of maximum 3 different types of hypotheses, and concrete tests to validate them related to my project so that I can say the objective has been achieved. Please be succint in your answers." }
    ])
  }

  type Example = {
    hypothesis: string;
    test: string;
    successCriteria: string;
    rationale: string;
  };

  // Function to parse JSON strings into objects
  function parseExamples(jsonStrings: string[]): Example[] {
    return jsonStrings.map(jsonString => JSON.parse(jsonString));
  }

  async function handleSubmit(): Promise<void> {
    const openai = new OpenAI({
      apiKey: API_KEY,
      dangerouslyAllowBrowser: true
    });
    setLoading(true);
    try {
      const result = await openai.chat.completions.create({
        messages: prompt,
        model: "gpt-4o",
        temperature: 0.5,
        max_tokens: 2048,
      });
      const examples = parseExamples(extractJSONStrings(result.choices[0].message.content));
      setExamples(examples);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }

  return <Card>
    <CardHeader title={
      <Typography variant="button" style={{ fontSize: '0.9em', marginBottom: '10px' }}>🤖 Scale Coach (beta)</Typography>

    } />
    <CardContent>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Hypothesis</TableCell>
              <TableCell>Implementation Plan</TableCell>
              <TableCell>Target Outcome</TableCell>
              <TableCell>Rationale</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && <div style={{
              width: '100%', display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>

              <ThreeDots
                height="80"
                width="80"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="rings-loading" />
              <Typography style={{ padding: "8px" }}>{getRandomLoadingText()}</Typography>

            </div>}
            {!loading && examples.map((example, index) => (
              <TableRow key={index}>
                <TableCell>{example.hypothesis}</TableCell>
                <TableCell>{example.test}</TableCell>
                <TableCell>{example.successCriteria}</TableCell>
                <TableCell>{example.rationale}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

    </CardContent>
    <CardActions>
      <Button label="Suggest targets" type="submit" color="primary" variant='outlined' disabled={loading} onClick={handleSubmit} />
    </CardActions>
  </Card>

};
/*

<>
                <div
                >
                  {discussionHistory
                    //.filter(message => message.role !== 'system')
                    .length > 0 && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <pre>
                          {discussionHistory.map((message, index) =>
                            <Card>
                              <CardHeader title={message.role}></CardHeader>
                              <CardContent>
                                <Typography display="block" style={{ textAlign: 'left' }}>{message.content}</Typography>
                              </CardContent>
                            </Card>
                          )}
                        </pre>
                      </div>
                    )}

                  <Card>
                    <form onSubmit={handleSubmit}>
                      <textarea
                        value={prompt}
                        placeholder="Please ask to openai"
                        onChange={(e) => setPrompt(e.target.value)}
                      ></textarea>
                      <button
                        disabled={loading || prompt.length === 0}
                        type="submit"
                      >
                        {loading ? "Generating..." : "Ask"}
                      </button>
                    </form>
                  </Card>

                </div>
              </>*/
export default HypothesisCoach


/*
 **Example answers:**
Hypothesis 1: If we conduct 20 in-depth customer interviews, then we will discover at least 3 critical pain points our product addresses, because thorough qualitative research reveals customers' needs.

Test: Schedule and conduct interviews, analyze for common themes.

Success Criteria: Identify at least 3 significant pain points aligned with our product.

Hypothesis 2: If we run a pilot with 5 customers for 3 months, then we'll see a 15% reduction in their energy consumption, because our platform optimizes usage.

Test: Implement the pilot, collect usage data before and after.

Success Criteria: Achieve an average 15% reduction across participants.
*/