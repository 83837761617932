import chroma from "chroma-js";

export const RESOLVED_STANDARD = chroma('green').brighten(1).hex();
export const RESOLVED_BRIGHT = chroma('green').brighten(2).hex();

export const IN_PROGRESS_BAR = "#71AAA5";
export const IN_PROGRESS_CELL = "#A1DCD6";


export const CARD_LIGHT = "#f0f0f0";

export const MATURITY_CATEGORY_COLORS = ['#ffd966', '#f6b26b', '#e69138', '#b45f06', '#990000', '#351c75', '#b7e1cd', '#6aa84f', '#38761d', '#274e13', '#6d9eeb', '#1155cc'];
export const MATURITY_CATEGORY_COLORS_RESOLVED = MATURITY_CATEGORY_COLORS.map(color => chroma.mix(RESOLVED_STANDARD, color).hex());


//const SET_COLORS = ['#636EFA', '#EF553B', '#00CC96', '#AB63FA', '#FFA15A', '#19D3F3', '#FF6692', '#B6E880', '#FF97FF', '#FECB52'];
// D3 colors

export const SCALE_COLORS = {
  maturityTable: {
    resolvedLevel: {
      checkbox: RESOLVED_STANDARD,
      cell: RESOLVED_BRIGHT,
    },
    unresolvedLevel: {
      checkbox: RESOLVED_STANDARD,
      cell: RESOLVED_BRIGHT,
    }
  }
}

export const SET_COLORS = ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf', '#ef586f',
  '#085166',
  '#4c7c04',
  '#93e567',
  '#f71b9f',
  '#098596',
  '#03a373',
  '#3d21f2',
  '#043b75',
  '#ecf725',
  '#1c69bc',
  '#3dc12e',
  '#bf0da4',
  '#4e46a8',
  '#34aa27',
  '#1b59b7',
  '#d84e75',
  '#a30afc',
  '#4ff2ec',
  '#47c0e8',
  '#35dd48',
  '#e24a7d',
  '#27678e',
  '#6223af',
  '#1dbc01',
  '#cae251',
  '#dd0d22',
  '#3b4ace',
  '#e5670d',
  '#560aad',
  '#f95c7e',
  '#bae564',
  '#acce23',
  '#8bf95c',
  '#31a1a5',
  '#b58526',
  '#ed4485',
  '#085166',
  '#487c04',
  '#98e567',
  '#f81b9f',
  '#088596',
  '#08a373',
  '#3821f2',
  '#083b75',
  '#e8f725',
  '#1869bc',
  '#38c12e',
  '#b80da4',
  '#4846a8',
  '#38aa27',
  '#1859b7',
  '#d94e75',
  '#a80afc',
  '#48f2ec',
  '#48c0e8',
  '#38dd48',
  '#e84a7d',
  '#28678e',
  '#6823af',
  '#18bc01',
  '#c8e251',
  '#d80d22',
  '#384ace',
  '#e8670d',
  '#580aad',
  '#f85c7e',
  '#b8e564',
  '#a8ce23',
  '#88f95c',
  '#38a1a5',
  '#b88526',
  '#e84485'];

const RedGreenScale = chroma.scale(['red', 'yellow', 'green']);

export const redGreenScaleBackgroundColor = (value: number) => {
  return RedGreenScale(value).hex();
}

export const redGreenScaleTextColor = (value: number) => {
  return RedGreenScale(value).luminance() < 0.5 ? 'white' : 'black';
}

/**
 * Returns a color that can be used on a given background color so that it is visible.
 * @param backgroundColor on which the text is written
 * @returns 
 */
export function getTextColorForBackground(backgroundColor: string) {
  const luminance = chroma(backgroundColor).luminance();
  return luminance < 0.4 ? 'white' : 'black';
}
