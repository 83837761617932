import React, { Fragment } from 'react';
import TableCell from '@mui/material/TableCell';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import Tooltip from '@mui/material/Tooltip';
import { MaturityDescriptionExtended } from "../../model/ScaleTypes";
import { toParagraphs } from "../../util/GuiUtils";
import { IN_PROGRESS_BAR, IN_PROGRESS_CELL, RESOLVED_BRIGHT, RESOLVED_STANDARD } from '../Colors';

export type MaturityDescriptionState = 'checked' | 'inprogress' | 'unchecked' | 'disabled';

export interface MaturityDesciptionCellProps {
  key: React.Key;
  maturityDescription: MaturityDescriptionExtended;
  state: MaturityDescriptionState;
  onClickDescriptionCheckbox: (clickedCategoryDescription: MaturityDescriptionExtended) => void;
  freemium: boolean;
  updatedAt: number;
}

function statusColor(state: MaturityDescriptionState, isCheckBox: boolean): string {
  if (isCheckBox) {
    if (state === 'checked') return RESOLVED_STANDARD
    if (state === 'inprogress') return IN_PROGRESS_BAR;
    return '#f4f4f4'
  }
  if (state === 'checked') return RESOLVED_BRIGHT;
  if (state === 'inprogress') return IN_PROGRESS_CELL;
  return 'rgba(255,255,255,0)'; // fully transparent
}

export const MaturityDescriptionTableCell: React.FC<MaturityDesciptionCellProps> = ({ updatedAt, freemium, maturityDescription, state, onClickDescriptionCheckbox }) => {
  const category = maturityDescription.category;
  const level = maturityDescription.level;

  const freemiumDisabled = (freemium && (level.level > 3 || category.order > 5));

  if (freemiumDisabled) state = 'disabled';
  const isDisabled = (state === 'disabled');

  const toolTipDescription =
    freemiumDisabled ? "FREEMIUM INCLUDES FIRST 3 LEVELS & 5 CATEGORIES" :
      toParagraphs(
        maturityDescription.description +
        (maturityDescription.examples?.length > 0 ? "\nExamples\n" + maturityDescription.examples : ""));

  return (<Fragment key={category.id}>
    { /** checkbox part */}
    {state === 'inprogress' ?
      <TableCell onClick={() => !isDisabled && onClickDescriptionCheckbox(maturityDescription)}
        style={{ backgroundColor: statusColor(state, true), padding: '0px', cursor: 'pointer' }} >
        <ModelTrainingIcon style={{ margin: '5px' }} />
      </TableCell> : <TableCell onClick={() => !isDisabled && onClickDescriptionCheckbox(maturityDescription)}
        style={{ backgroundColor: statusColor(state, true), padding: '0px', cursor: 'pointer' }} >
        {state === 'checked' && <CheckCircleIcon style={{ margin: '5px' }} />}
      </TableCell>}
    { /** content part */}
    <TableCell onClick={() => !isDisabled && onClickDescriptionCheckbox(maturityDescription)} className={category.name === "Financial Model" ? "fin_" + level.level : "" || category.name === "Team Composition" ? "team_" + level.level : ""}
      style={{ backgroundColor: statusColor(state, false), cursor: 'pointer' }}
    >
      <p style={{ fontSize: '0.85em' }}>{maturityDescription.name}
        {maturityDescription.description?.length > 0 ?
          <Tooltip title={toolTipDescription} arrow>
            <InfoIcon style={{ transform: "scale(0.5)", padding: -3, margin: -3, verticalAlign: "bottom", color: "orange" }} fontSize="small" />
          </Tooltip>
          : ""}
      </p>
    </TableCell>
  </Fragment >)

}