import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { Paper, Box, Grid, List, ListItem, ListItemIcon, ListItemText, Switch, Tab, Divider, Alert } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Business, MaturityDescriptionExtended, MaturityScoreExtended, MaturityTool } from '../../model/ScaleTypes';
import { TrelloBoard, TrelloIcon, TrelloList } from '../../integrations/trello/trelloApi';
import Tabs from '@mui/material/Tabs';
import { toParagraphs } from '../../util/GuiUtils';
import { HistoryNoteCard } from '../cards/HistoryNoteCard';
import HypothesisCoach from '../../pages/OpenAI';
import { CARD_LIGHT, RESOLVED_BRIGHT } from '../Colors';

export enum NoteStatus {
  RESOLVED,
  UNRESOLVED
}

export interface BusinessTrelloConfig {
  isTrelloConfigured: boolean,
  isBusinessConfigured: boolean,
  businessId: string,
  board?: TrelloBoard,
  list?: TrelloList,
}

export interface MaturityNotesDialogProps {
  business: Business;
  isDialogOpen: boolean;
  onCloseDialog?: () => void;
  onSaveNote?: (maturityDescrption: MaturityDescriptionExtended, status: NoteStatus, newNoteText: string, makeTrelloCard: boolean) => Promise<void>;
  onMaturityNoteUpdate?: (noteToUpdate: MaturityScoreExtended) => void;
  onMaturityNoteDelete?: (noteToDelete: MaturityScoreExtended) => void;
  maturityDescription: null | MaturityDescriptionExtended;
  latestMaturityScore?: MaturityScoreExtended;
  maturityScoreArray: MaturityScoreExtended[];
  trelloData?: BusinessTrelloConfig | null
}

export const MaturityNotesDialog: React.FC<MaturityNotesDialogProps> = ({
  business,
  isDialogOpen,
  onCloseDialog = () => { },
  onSaveNote = () => { },
  onMaturityNoteUpdate = () => { },
  onMaturityNoteDelete = () => { },
  maturityDescription,
  latestMaturityScore,
  maturityScoreArray = [],
  trelloData,
  ...props
}) => {
  const noteFieldRef = React.useRef<HTMLInputElement>();
  const [makeTrelloCard, setMakeTrelloCard] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = React.useState<number>(-1);

  const handleTabChange = (event: React.SyntheticEvent, tabIndex: number) => {
    setCurrentTab(tabIndex);
  };

  useEffect(() => {
    setCurrentTab(-1);
  }, [latestMaturityScore])

  if (!maturityDescription) return null; // nothing selected for the dialog
  if (!business) return <Alert severity='error'>Missing business data.</Alert>

  const scoresWithNotes = maturityScoreArray
    .filter(history => history.maturityCategoryId === maturityDescription.maturityCategoryId)
    .filter(score => score.notes);

  const handleTrelloSwitchChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setMakeTrelloCard(event.target.checked);
  };

  const handleSaveResolved = () => {
    onSaveNote(maturityDescription, NoteStatus.RESOLVED, noteFieldRef.current!.value, makeTrelloCard);
  };

  const handleSaveUnresolved = () => {
    onSaveNote(maturityDescription, NoteStatus.UNRESOLVED, noteFieldRef.current!.value, makeTrelloCard);
  };

  const tools = maturityDescription.tools?.filter(tool => tool.title) || [];
  const showTabs = tools.length > 0 || maturityDescription.description

  // TODO to separate to it's own component
  const IntegrationActions = trelloData && trelloData.isTrelloConfigured ?
    trelloData.isBusinessConfigured ?
      <>
        <Switch
          checked={makeTrelloCard}
          onChange={handleTrelloSwitchChange}
          name="createCardSwitch"
        />
        <Button type="submit" onClick={() => setMakeTrelloCard(!makeTrelloCard)}>
          <TrelloIcon style={{ height: "14px", alignItems: "center", placeItems: "center" }} />
          <Typography style={{ fontSize: 10 }}>Create card to {trelloData.board?.name} ({trelloData.list?.name})</Typography>
        </Button>
      </>
      :
      <Button title="Link Business" onClick={() => { window.open(`/scale_business/${trelloData.businessId}/1`) }}><Typography style={{ fontSize: 10 }}><TrelloIcon style={{ alignItems: "center", placeItems: "center" }} />&nbsp;Link Business to Trello</Typography></Button>
    :
    <Button title="Setup Integrations" onClick={() => { window.open("/integrations") }}><Typography style={{ fontSize: 10 }}>Setup integrations</Typography></Button>

  return <Dialog fullWidth maxWidth={'md'} open={isDialogOpen} onClose={onCloseDialog} aria-labelledby="form-dialog-title">
    <DialogContent>
      <Typography style={{ fontWeight: "bold", fontSize: '0.9em', color: 'grey' }}>{maturityDescription?.category?.name} - Level {maturityDescription?.level?.level}: {maturityDescription?.level?.name}</Typography>
      <Paper style={{ padding: '20px', backgroundColor: CARD_LIGHT }}>
        <Typography display="block" style={{ fontSize: '1.3em', fontWeight: 'bold', textAlign: 'center' }}>
          {maturityDescription?.name}
        </Typography>
      </Paper>
      {showTabs &&
        <Box sx={{ width: '100%' }} >
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={currentTab} onChange={handleTabChange} aria-label="tabs">
              <Tab label="description" value={-1} />
              {tools?.map((tool, i) => <Tab label={`Tool: ${tool.title}`} value={i} />)}
            </Tabs>
          </Box>
          {currentTab < 0 &&
            <Box style={{ padding: '12px' }}>
              {maturityDescription.description.length > 0 &&
                <Typography whiteSpace="pre-line" display="block" style={{ marginBottom: '10px' }}>
                  {maturityDescription?.description}
                </Typography>
              }
              {maturityDescription?.examples?.length > 0 && <>
                <Typography style={{ fontSize: '0.9em', marginBottom: '10px' }}>Examples:</Typography>
                <Typography display="block">{maturityDescription?.examples}</Typography>
              </>}
            </Box>
          }
          {tools && currentTab >= 0 && currentTab <= tools.length &&
            <Box style={{ padding: '12px' }} >
              <ToolTabContent key={currentTab} item={tools[currentTab]} />
            </Box>}
        </Box>}
      <Divider />
      <Box style={{ padding: '12px' }}>
        <Typography variant="button" style={{ fontSize: '0.9em', marginBottom: '10px' }}>Comments</Typography>

        <Grid container>
          <Grid item sm={12} md={8}>
            <TextField
              autoFocus
              margin="dense"
              id="notes"
              type="text"
              multiline
              fullWidth
              variant="outlined"
              minRows={8}
              inputRef={noteFieldRef}
              placeholder="What evidence justifies that you have completed this objective? What is still missing? Write it down here."
            />
          </Grid>
          <Grid item sm={12} md={4} >
            {
              <List dense={true}>
                <ListItem>
                  <ListItemIcon><ArrowRightIcon /></ListItemIcon>
                  <ListItemText primary="What evidence justifies that you have completed this objective?" />
                </ListItem>
                <ListItem>
                  <ListItemIcon><ArrowRightIcon /></ListItemIcon>
                  <ListItemText primary="What is still missing?" />
                </ListItem>
                <ListItem>
                  <ListItemIcon><ArrowRightIcon /></ListItemIcon>
                  <ListItemText primary="What are the next tasks?" />
                </ListItem>
                <ListItem>
                  <ListItemIcon><ArrowRightIcon /></ListItemIcon>
                  <ListItemText primary="Who does what?" />
                </ListItem>
              </List>
            }
          </Grid>
        </Grid>

        <Grid item>
          <HypothesisCoach business={business} maturityDescription={maturityDescription} scoresWithNotes={scoresWithNotes} />
        </Grid>

        {scoresWithNotes?.map((note: MaturityScoreExtended, index: number) =>
          (<Grid key={index} item><HistoryNoteCard note={note} onMaturityNoteDelete={onMaturityNoteDelete} onMaturityNoteUpdate={onMaturityNoteUpdate} /></Grid>))}
      </Box>
    </DialogContent>

    <DialogActions>
      <Box width="100%" display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          {IntegrationActions}
        </Box>
        <Box>
          <Button onClick={onCloseDialog} color="primary" variant='text' style={{ marginRight: '8px' }}>Cancel</Button>
          <Button type="submit" onClick={() => { handleSaveUnresolved() }} color="primary" variant='outlined' style={{ marginRight: '8px' }}>
            Save Progress
          </Button>
          <Button type="submit" onClick={() => { handleSaveResolved() }} color="primary" variant='contained' style={{ backgroundColor: RESOLVED_BRIGHT, color: '#253241', marginRight: '8px' }}>
            <CheckCircleIcon style={{ paddingRight: '5px' }} />Save & Complete
          </Button>
        </Box>
      </Box>
    </DialogActions>
  </Dialog >
};

function ToolTabContent(props: { item: MaturityTool }) {
  if (!props.item) return <></>
  return (
    <Box style={{ padding: '12px' }}>
      <img src={props.item.img ?? '/tools-placeholder.png'} alt="Tools and Instructions" style={{ maxHeight: '500px', maxWidth: '370px', marginBottom: '10px' }} /><br />
      <Typography variant='button' style={{ fontSize: '0.9em', fontWeight: 'bold', marginBottom: '10px' }}>{props.item.title ?? ""}</Typography>
      <Typography display="block" style={{ marginBottom: '10px' }}>{toParagraphs(props.item.description) ?? ""}</Typography>
      <Grid container alignContent='center' alignItems='center' direction='column'>
        {props.item["ext-url"] && <Grid item xs={3}>
          <Typography variant='button'><a href={props.item["ext-url"] ?? ""} rel="noreferrer" target="_blank">Read more</a></Typography>
        </Grid>}
        {props.item.canvas && <Grid item xs={3}>
          <Typography variant='button'><a href={props.item.canvas ?? ""} rel="noreferrer" target="_blank">Download canvas</a></Typography>
        </Grid>}
      </Grid>
    </Box>
  )
}

